import axios from '../utils/axios';
const api = {
    getPermissions: '/api/PersonPermission/get',
    login:'/api/Person/Login',
    getAddressifo:'/api/PersonPermission/GetAddressifo'
}
/**
 * 登录
 * @param {*} params
 */
export function GetPermissions(params) {
    return axios({
        url: api.getPermissions,
        method: 'get',
        params
    });
}
/**
* 登录
* @param {*} data
*/
export function UserLogin(data) {
    return axios({
        url: api.login,
        method: 'post',
        data
    });
}
/**
 * 登录
 * @param {*} params
 */
export function GetAddressifo(params) {
    return axios({
        url: api.getAddressifo,
        method: 'get',
        params
    });
}